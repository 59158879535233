import { ServiceTypeEnum, StudentOrderDetailStateEnum } from "../enums/enums"
import { Semester } from "./Semester"
import { ServicePrice } from "./ServicePrice"
import { TimePeriod } from "./TimePeriod"

export interface IStudentOrderDetail {
  id: string
  semester: Semester
  serviceId: string
  serviceName: string
  timePeriod: TimePeriod
  price: number
  state: StudentOrderDetailStateEnum
  servicePrice: ServicePrice
  isValid: boolean
  status: string
}

export class StudentOrderDetail implements IStudentOrderDetail {
  id: string
  semester: Semester
  serviceId: string
  serviceName: string
  timePeriod: TimePeriod
  price: number
  state: StudentOrderDetailStateEnum
  servicePrice: ServicePrice
  isValid: boolean
  status: string

  constructor() {
    this.id = ""
    this.semester = {} as Semester
    this.serviceId = ""
    this.serviceName = ""
    this.timePeriod = {} as TimePeriod
    this.price = 0
    this.state = StudentOrderDetailStateEnum.Draft
    this.servicePrice = {
      minPrice: 0,
      maxPrice: 0,
    } as ServicePrice
    this.isValid = false
    this.status = ""
  }
}

export interface StudentOrderDetailGetDto {
  semesterName: string
  semesterId: string
  timePeriodName: string
  timePeriodId: string
  serviceName: string
  serviceId: string
  price: number
  state: number
  stateName: string
  serviceType: ServiceTypeEnum
}
