
import { computed, defineComponent, onMounted, ref } from "vue"
import { Delete, Edit, Plus, Money, DocumentAdd } from "@element-plus/icons-vue"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import StudentSaleDetail from "@/components/education/student/sale/detail.vue"
import StudentSaleInvoice from "@/components/education/student/sale/invoice.vue"
import StudentSalePayment from "@/components/education/student/sale/payment.vue"
import { currencyForm } from "@/core/helpers/currencyformat"
import { StudentOrderSummary } from "@/core/models/StudentOrderSummary"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  components: { StudentSaleDetail, StudentSaleInvoice, StudentSalePayment },
  name: "student-sale",
  inheritAttrs: false,
  props: ["studentId", "studentName", "branchId", "businessId", "personId"],
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const invoiceId = ref()

    onMounted(async () => {
      if (!route.params.student_id) router.push("dashboard-summary")
    })

    const saleList = computed(() => {
      return store.getters.getStudentOrderList as StudentOrderSummary[]
    })

    const getSaleList = async studentId => {
      isSaleListLoading.value = true
      await store.dispatch(Actions.FETCH_STUDENT_ORDERS, studentId)
      isSaleListLoading.value = false
    }

    const isSaleListLoading = ref(false)

    const handlSaleAdd = () => {
      router.push({ name: "student-sale-add" })
    }

    const handlSaleEdit = id => {
      router.push({
        name: "student-sale-detail",
        params: { student_id: props.studentId, student_order_id: id },
      })
    }

    const handlSaleDelete = async id => {
      console.log(id)
      await getSaleList(id)
    }

    const handlSalePayment = async (id, _invoiceId) => {
      invoiceId.value = _invoiceId
      router.push({
        name: "student-payment",
        params: { student_id: props.studentId, student_order_id: id },
      })
    }

    const handlSaleInvoice = async id => {
      router.push({
        name: "student-invoice",
        params: { student_id: props.studentId, student_order_id: id },
      })
    }

    return {
      saleList,
      invoiceId,

      isSaleListLoading,

      Delete,
      Edit,
      Plus,
      Money,
      DocumentAdd,

      handlSaleAdd,
      handlSaleEdit,
      handlSaleDelete,
      handlSalePayment,
      handlSaleInvoice,
      getSaleList,
      currencyForm,
    }
  },
})
