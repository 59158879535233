
import { computed, defineComponent, ref, watch } from "vue"

import { Close, Delete, Edit, Plus } from "@element-plus/icons-vue"
import ApiService from "@/core/services/ApiService"
import { StudentOrderAdd } from "@/core/models/StudentOrderAdd"
import { SelectModel } from "@/core/models/SelectModel"
import { ElButton, ElForm, ElMessage, FormItemRule } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import {
  ReportType,
  Role,
  SaleStepEnum,
  StudentOrderDetailStateEnum,
  StudentOrderStateEnum,
} from "@/core/enums/enums"
import { PaymentSchedule } from "@/core/models/PaymentSchedule"
import { PaymentScheduleItem } from "@/core/models/PaymentScheduleItem"
import { GeneratePaymentSchedule } from "@/core/models/GeneratePaymentSchedule"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import { today } from "@/core/helpers/dateformat"
import { currencyForm } from "@/core/helpers/currencyformat"
import { StudentOrderSummary } from "@/core/models/StudentOrderSummary"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import { ServiceHierarchy } from "@/core/models/ServiceHierarchy"
import { useRoute, useRouter } from "vue-router"
import { StudentOrderDetail } from "@/core/models/StudentOrderDetail"

export default defineComponent({
  components: { ElInputCurrency, ElButton },
  name: "student-sale-detail",
  inheritAttrs: false,
  props: ["add", "personId", "studentName", "businessId", "branchId"],
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isDrawerOpen = computed(() => route.meta.addSale || route.meta.editSale)
    const saleStep = ref<SaleStepEnum>(SaleStepEnum.Sale)
    const saleList = computed(() => {
      return store.getters.getStudentOrderList as StudentOrderSummary[]
    })
    const parentList = computed(() => {
      return store.getters.getStudentParentList.map(x => ({
        id: x.personId,
        name: (x.name + " " + x.surname).toLocaleUpperCase("tr-TR"),
      }))
    })

    const generatePaymentSchedule = ref<GeneratePaymentSchedule>({
      installmentAmount: 1,
      downPayment: 0,
      paymentStartDate: today(),
    } as GeneratePaymentSchedule)

    const paymentSchedule = ref<PaymentSchedule>({
      paymentPersonId: props.personId,
      paymentSchedules: [{ amount: 0 } as PaymentScheduleItem] as PaymentScheduleItem[],
    } as PaymentSchedule)

    const personnelList = ref<SelectModel[]>([])

    const sale = ref<StudentOrderAdd>({} as StudentOrderAdd)

    //TODO: tipe çevrilmeli
    const saleDetails = ref<StudentOrderDetail[]>([new StudentOrderDetail()])

    const isSaleDisabled = computed(() => !!sale.value.id)

    const activeCurrencySuffix = "₺"

    const isPaymentScheduleDisabled = computed(
      () => sale.value.state != StudentOrderStateEnum.Draft
    )

    const totalPaymentScheduleAmount = computed(() => {
      let sum = 0
      if (paymentSchedule.value.paymentSchedules) {
        paymentSchedule.value.paymentSchedules.forEach(x => {
          sum += x.amount
        })
        return Math.round(sum * 1e12) / 1e12
      } else return 0
    })

    const getPayerList = computed(() => {
      return [
        {
          id: props.personId,
          name: props.studentName,
        },
      ].concat(parentList.value)
    })

    type FormInstance = InstanceType<typeof ElForm>

    const saleDetailRuleFormRef = ref<FormInstance>()
    const saleRuleFormRef = ref<FormInstance>()
    const paymentScheduleRuleFormRef = ref<FormInstance>()
    const generatePaymentScheduleRuleFormRef = ref<FormInstance>()

    const validateDetails = () => {
      if (saleDetails.value.length == 0) {
        ElMessage.error("En az bir geçerli satış eklemelisiniz!")
        return false
      } else {
        for (const item of saleDetails.value) {
          if (!item.isValid) {
            ElMessage.error("Satışlardan biri geçersiz!")
            return false
          }
        }
        return true
      }
    }

    const saleRules = ref({})

    const paymentScheduleRules = ref({
      paymentPersonId: getRule(RuleTypes.SELECT, "Ödeme Sorumlusu"),
      paymentSchedules: [
        {
          type: "array",
          required: true,
          min: 1,
          fields: {
            date: { type: "date", required: true },
            amount: { type: "number", required: true },
          },
        } as FormItemRule,
      ],
    })

    const generatePaymentScheduleRules = ref({
      installmentAmount: getRule(RuleTypes.NUMBER, "Ödeme Sorumlusu"),
      downPayment: getRule(RuleTypes.NUMBER, "Peşinat"),
      paymentStartDate: getRule(RuleTypes.DATE, "İlk Taksit Tarihi"),
    })

    async function getServicePrice(currentSale) {
      currentSale.servicePrice = undefined
      const config = {
        params: {
          businessIds: [props.businessId],
          branchIds: [props.branchId],
          semesterIds: [currentSale.semester?.id],
          timePeriodIds: [currentSale.timePeriod?.id],
          serviceIds: [currentSale.serviceId],
        },
      }
      const { data } = await ApiService.query("service-price", config)

      if (data.length > 0) {
        currentSale.servicePrice = data[0]
      }
    }

    // Sadece Fiyat değiştiğinde satış türü fiyat listesini tekrar almakla uğraşmasın diye 'onlyPriceChanged' eklendi
    const checkSaleRow = async (currentSale, onlyPriceChanged = false) => {
      //TODO: async validator ile fluent hale getirilmeli
      if (!currentSale.semester || currentSale.semester.id == "") {
        currentSale.status = "Dönem Seçiniz"
        currentSale.valid = false
        return
      }

      if (currentSale.serviceId == "") {
        currentSale.status = "Satış Türü Seçiniz"
        currentSale.valid = false
        return
      }

      if (!currentSale.timePeriod || currentSale.timePeriod.id == "") {
        currentSale.status = "Zaman Seçiniz"
        currentSale.valid = false
        return
      }

      if (!onlyPriceChanged) {
        await getServicePrice(currentSale)
      }

      if (currentSale.servicePrice != undefined) {
        currentSale.isValid = true
        currentSale.status = "Geçerli satış"
      } else {
        currentSale.isValid = false
        currentSale.status = "Satış türü-fiyat tanımlanmamış!"
        currentSale.servicePrice = undefined
        return
      }

      if (
        currentSale.servicePrice &&
        (currentSale.price == undefined ||
          currentSale.price < currentSale.servicePrice.minPrice ||
          currentSale.price > currentSale.servicePrice.maxPrice)
      ) {
        currentSale.isValid = false
        currentSale.status = "Fiyat aralığı satış türü ile uyumsuz!"
      } else {
        currentSale.isValid = true
        currentSale.status = ""
      }
    }

    const getPaymentSchedule = async (invoiceId, paymentPersonId) => {
      isPaymentScheduleListLoading.value = true
      const { data } = await ApiService.get("invoice/" + invoiceId + "/payment-schedule")
      isPaymentScheduleListLoading.value = false
      paymentSchedule.value.paymentSchedules = data
      paymentSchedule.value.paymentPersonId = paymentPersonId
    }

    const getSaleList = async studentId => {
      isSaleListLoading.value = true
      await store.dispatch(Actions.FETCH_STUDENT_ORDERS, studentId)
      isSaleListLoading.value = false
    }

    const handleContract = async studentOrderId => {
      await ApiService.report({
        report: ReportType.SchoolSaleContract,
        payload: JSON.stringify({
          studentOrderId,
        }),
      })
    }

    const addSale = async () => {
      const addData = {
        ...sale.value,
        studentId: route.params.student_id,
        details: saleDetails.value.map(x => ({
          semesterId: x.semester.id,
          serviceId: x.serviceId,
          timePeriodId: x.timePeriod.id,
          price: x.price,
        })),
      }
      const { data } = await ApiService.post("student-order/add", addData)

      sale.value = {
        ...sale.value,
        id: data.id,
        state: StudentOrderStateEnum.Draft,
        price: saleDetails.value.reduce((sum, current) => sum + current.price, 0),
      }

      if (paymentSchedule.value.paymentSchedules.length == 1) {
        paymentSchedule.value.paymentSchedules[0].amount = sale.value.price
        paymentSchedule.value.paymentSchedules[0].date = today()
      }

      saleStep.value = SaleStepEnum.PaymentSchedule
      await getSaleList(route.params.student_id)
    }

    const handleAddNewLine = () => {
      saleDetails.value.push(new StudentOrderDetail())
    }

    const handleRemoveSaleRow = currentSale => {
      if (saleDetails.value.length == 1) {
        ElMessage.error("En az 1 satış kaydı olmalıdır!")
        return
      }
      const updatedSaleDetails = [...saleDetails.value]
      saleDetails.value = []
      updatedSaleDetails.splice(saleDetails.value.indexOf(currentSale), 1)
      saleDetails.value = updatedSaleDetails
    }

    const addPaymentSchedule = async () => {
      const data = {
        ...paymentSchedule.value,
        studentOrderId: sale.value.id,
      }
      await ApiService.post("student-order/add/payment-schedule", data)

      sale.value = {
        ...sale.value,
        state: StudentOrderStateEnum.PaymentScheduled,
      }

      await getSaleList(route.params.student_id)
    }

    const getSale = async id => {
      const response = await ApiService.get("student-order/" + id)

      sale.value = response.data
      saleDetails.value = sale.value.studentOrderDetails.map(item => ({
        semester: {
          id: item.semesterId,
          name: item.semesterName,
          code: "",
          businessId: "",
          isActive: false,
        },
        timePeriod: {
          id: item.timePeriodId,
          name: item.timePeriodName,
          code: "",
          businessId: "",
        },
        serviceId: item.serviceId,
        serviceName: item.serviceName,
        servicePrice: {
          id: "",
          hasDate: false,
          serviceId: "",
          semesterId: "",
          branchId: "",
          businessId: "",
          timePeriodId: "",
          startDate: new Date(),
          endDate: new Date(),
          minPrice: 0,
          maxPrice: 0,
        },
        isValid: true,
        status: "",
        id: "",
        price: item.price,
        state: StudentOrderDetailStateEnum.Draft,
      }))

      saleRuleFormRef.value?.resetFields()
      if (sale.value.state == StudentOrderStateEnum.Draft) {
        if (paymentSchedule.value.paymentSchedules) {
          paymentSchedule.value.paymentSchedules[0].amount = sale.value.price
          paymentSchedule.value.paymentSchedules[0].date = today()
        }
      } else {
        await getPaymentSchedule(sale.value.invoiceId, sale.value.accountCardPersonId)
      }
    }

    watch(
      () => route.meta.editSale,
      async newValue => {
        if (newValue) {
          await getSale(route.params.student_order_id)
        }
      }
    )

    watch(
      () => props.personId,
      async newValue => {
        if (newValue) {
          paymentSchedule.value.paymentPersonId = newValue

          await getPersonnelList()
        }
      }
    )

    watch(
      () => route.meta.addSale,
      async newValue => {
        if (newValue) {
          resetForm()
        }
      }
    )

    watch(
      () => props.businessId,
      async newValue => {
        if (newValue) {
          await getSemesterList(newValue)
          await getTimePeriodList(newValue)
          await getServiceList(newValue)
        }
      }
    )

    const handleSubmitSale = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          if (validateDetails()) {
            await addSale()
          }
        }
      })
    }

    const handleSubmitPaymentSchedule = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await addPaymentSchedule()
        }
      })
    }

    const handleDelete = (index, row) => {
      //TODO order silme api'sini bekliyor
      console.log(index, row)
    }

    const addNewPaymentSchedule = () => {
      const data = paymentSchedule.value
      generatePaymentSchedule.value.installmentAmount =
        paymentSchedule.value.paymentSchedules.length + 1
      paymentSchedule.value = {
        ...paymentSchedule.value,
        paymentSchedules: [...data.paymentSchedules, { amount: 0 } as PaymentScheduleItem],
      }
    }

    const removePaymentSchedule = index => {
      const data = paymentSchedule.value
      generatePaymentSchedule.value.installmentAmount =
        paymentSchedule.value.paymentSchedules.length - 1
      data.paymentSchedules.splice(index, 1)
      paymentSchedule.value = {
        ...paymentSchedule.value,
        paymentSchedules: [...data.paymentSchedules],
      }
    }

    const semesterList = ref<SelectModel[]>([])
    const timePeriodList = ref<SelectModel[]>([])
    const serviceList = ref<ServiceHierarchy[]>([])

    const isSemesterSelectLoading = ref(false)
    const isPersonnelSelectLoading = ref(false)
    const isTimePeriodSelectLoading = ref(false)
    const isServiceSelectLoading = ref(false)
    const isSaleListLoading = ref(false)
    const isPaymentScheduleListLoading = ref(false)

    const getSemesterList = async businessId => {
      isSemesterSelectLoading.value = true
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data
      isSemesterSelectLoading.value = false
    }

    const getPersonnelList = async () => {
      const query = {
        params: {
          userType: Role.Personnel,
        },
      }
      isPersonnelSelectLoading.value = true
      const { data } = await ApiService.query(`user`, query)
      personnelList.value =
        data.length > 0
          ? data[0].users.map(item => ({
              id: item.id,
              name:
                item.firstName.toLocaleUpperCase("tr-TR") +
                " " +
                item.lastName.toLocaleUpperCase("tr-TR"),
            }))
          : []
      isPersonnelSelectLoading.value = false
    }

    const getService = id => {
      const services = serviceList.value
      const service = services.find(s => s.id === id)
      return service ? service.name : ""
    }

    const getTimePeriodList = async businessId => {
      isTimePeriodSelectLoading.value = true
      const { data } = await ApiService.get("time-period/business/" + businessId)
      timePeriodList.value = data
      isTimePeriodSelectLoading.value = false
    }

    const getServiceList = async businessId => {
      isServiceSelectLoading.value = true
      const query = {
        params: {
          isWithService: true,
        },
      }
      const { data } = await ApiService.query(
        `service-category/business/${businessId}/childs`,
        query
      )
      serviceList.value = data
      isServiceSelectLoading.value = false
    }

    const serviceTreeSelectProps = {
      label: "name",
      children: "childs",
      value: "id",
      disabled: data => {
        return !data.isService
      },
    }

    const goPaymentScheduleStep = () => {
      saleStep.value = SaleStepEnum.PaymentSchedule
    }

    const goSaleStep = () => {
      saleStep.value = SaleStepEnum.Sale
    }

    const resetForm = () => {
      goSaleStep()

      sale.value = {} as StudentOrderAdd
      saleDetails.value = [new StudentOrderDetail()]

      saleRuleFormRef.value?.resetFields()

      paymentSchedule.value = {
        paymentSchedules: [{ amount: 0 } as PaymentScheduleItem] as PaymentScheduleItem[],
      } as PaymentSchedule
      generatePaymentSchedule.value = {
        installmentAmount: 1,
        downPayment: 0,
        paymentStartDate: today(),
      } as GeneratePaymentSchedule
    }

    const handleSaleDrawerClose = () => {
      saleDetails.value = []
      closeDrawer()
    }

    const getPriceDifferenceType = computed(() => {
      if (sale.value.price > totalPaymentScheduleAmount.value) return "warning"
      else if (sale.value.price < totalPaymentScheduleAmount.value) return "danger"
      else return "success"
    })

    const calculatePaymentSchedules = async () => {
      const addData = {
        ...generatePaymentSchedule.value,
        totalPrice: sale.value.price,
      }
      const { data } = await ApiService.post("payment-schedule/generate", addData)
      paymentSchedule.value.paymentSchedules = data
    }

    const handleGeneratePaymentSchedule = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await calculatePaymentSchedules()
        }
      })
    }

    const closeDrawer = () => {
      router.push({
        name: "student-sale",
        params: { student_id: route.params.student_id },
      })
    }

    return {
      currencyForm,

      paymentScheduleRuleFormRef,
      generatePaymentScheduleRuleFormRef,
      saleRuleFormRef,
      saleDetailRuleFormRef,
      generatePaymentScheduleRules,
      paymentScheduleRules,
      saleRules,

      generatePaymentSchedule,

      saleList,
      semesterList,
      serviceList,
      timePeriodList,
      personnelList,
      getPayerList,

      isPaymentScheduleDisabled,
      isSaleListLoading,
      isPaymentScheduleListLoading,
      isPersonnelSelectLoading,
      isTimePeriodSelectLoading,
      isDrawerOpen,
      isSaleDisabled,

      saleDetails,
      sale,
      saleStep,
      paymentSchedule,

      serviceTreeSelectProps,
      getPriceDifferenceType,
      totalPaymentScheduleAmount,
      SaleStepEnum,
      StudentOrderStateEnum,
      activeCurrencySuffix,

      Delete,
      Edit,
      Plus,
      Close,

      addSale,
      addNewPaymentSchedule,

      getServicePrice,
      getService,

      handleContract,
      handleRemoveSaleRow,
      handleSubmitSale,
      handleSubmitPaymentSchedule,
      handleDelete,
      handleSaleDrawerClose,
      handleGeneratePaymentSchedule,
      handleAddNewLine,

      removePaymentSchedule,

      checkSaleRow,
      calculatePaymentSchedules,

      goPaymentScheduleStep,
      goSaleStep,
      closeDrawer,
    }
  },
})
