
import { computed, defineComponent, onMounted, ref } from "vue"

import { Close, DocumentAdd, Delete } from "@element-plus/icons-vue"
import ApiService from "@/core/services/ApiService"

import { ElForm, FormItemRule } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { SaleStepEnum, StudentOrderStateEnum } from "@/core/enums/enums"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { useStore } from "vuex"
import { SelectModel } from "@/core/models/SelectModel"
import StudentInvoice from "@/core/models/StudentInvoice"
import { InternalRuleItem, Value } from "async-validator"
import { Arrayable } from "element-plus/lib/utils"
import { Actions } from "@/store/enums/StoreEnums"
import { currencyForm } from "@/core/helpers/currencyformat"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  name: "student-sale-invoice",
  inheritAttrs: false,
  props: ["studentId"],
  setup(props) {
    const store = useStore()

    const route = useRoute()
    const router = useRouter()

    const isDrawerOpen = computed(() => route.meta.saleInvoice)
    const isSaleListLoading = ref(false)

    const cityId = ref()
    const districtId = ref()
    const invoice = ref<StudentInvoice>({
      isCorporateInvoice: false,
      isSingleInvoice: true,
    } as StudentInvoice)

    type FormInstance = InstanceType<typeof ElForm>

    const invoiceRuleFormRef = ref<FormInstance>()

    const cityList = ref<SelectModel[]>([])
    const districtList = ref<SelectModel[]>([])
    const saleList = computed(() => {
      return store.getters.getStudentOrderHasNotOrderTrackerList
    })

    const getSaleList = async studentId => {
      isSaleListLoading.value = true
      await store.dispatch(Actions.FETCH_STUDENT_ORDERS, studentId)
      isSaleListLoading.value = false
    }

    const formModel = computed(() => ({
      ...invoice.value,
      cityId: cityId.value,
      districtId: districtId.value,
    }))

    const validateByCorporate = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if (!value && invoice.value.isCorporateInvoice) {
        callback(new Error(`Lütfen ${rule.field} Giriniz!`))
      } else callback()
    }

    const corporateInfoRules = [
      {
        validator: validateByCorporate,
        trigger: "blur",
      },
    ] as Arrayable<FormItemRule>

    const invoiceRules = {
      cityId: getRule(RuleTypes.SELECT, "Şehir"),
      address: getRule(RuleTypes.TEXT100, "Adres"),
      districtId: getRule(RuleTypes.SELECT, "İlçe"),
      title: corporateInfoRules,
      taxNumber: corporateInfoRules,
      taxOffice: corporateInfoRules,
    }

    const closeDrawer = async () => {
      router.push({ name: "student-sale", params: { student_id: route.params.student_id } })
    }

    const onInvoiceDrawerClose = () => {
      cityId.value = undefined
      districtId.value = undefined

      invoice.value = {
        isCorporateInvoice: false,
        isSingleInvoice: true,
      } as StudentInvoice

      invoiceRuleFormRef.value?.resetFields()

      closeDrawer()
    }

    const handleSubmitInvoice = formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await addInvoice()
        }
      })
    }

    onMounted(async () => {
      await getCityList()
    })

    const getCityList = async () => {
      const { data } = await ApiService.get("location/cities")
      cityList.value = data
    }

    const getDistrictList = async cityId => {
      const { data } = await ApiService.get("location/cities/" + cityId + "/districts")
      districtList.value = data
    }

    const addInvoice = async () => {
      let city = cityList.value.find(x => x.id == cityId.value)
      let district = districtList.value.find(x => x.id == districtId.value)
      if (!city || !district) return
      const data = {
        ...invoice.value,
        studentOrderId: route.params.student_order_id,
        address: `${invoice.value.address} - ${city.name} - ${district.name}`,
      }
      await ApiService.post("student-order/add/invoice", data)
      await getSaleList(props.studentId)
      await closeDrawer()
    }

    return {
      invoiceRules,
      invoiceRuleFormRef,

      saleList,
      cityList,
      districtList,

      invoice,

      isSaleListLoading,
      isDrawerOpen,

      DocumentAdd,
      Close,
      Delete,

      DateFormatTypes,
      dateForm,

      closeDrawer,
      handleSubmitInvoice,
      getCityList,
      getDistrictList,
      onInvoiceDrawerClose,

      SaleStepEnum,
      StudentOrderStateEnum,

      cityId,
      districtId,

      formModel,
      currencyForm,
    }
  },
})
