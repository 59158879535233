
import { computed, defineComponent, ref, watch } from "vue"

import { Close, Money, Document } from "@element-plus/icons-vue"
import ApiService from "@/core/services/ApiService"

import { ElForm, FormItemRule, UploadProps, ElMessage, TabPanelName } from "element-plus"
import { SelectModel } from "@/core/models/SelectModel"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { InternalRuleItem } from "async-validator"
import { ReportType, SaleStepEnum, StudentOrderStateEnum } from "@/core/enums/enums"
import { Payment } from "@/core/models/Payment"
import { Cheque } from "@/core/models/Cheque"
import { CashBook } from "@/core/models/CashBook"
import { DateFormatTypes, dateForm, today } from "@/core/helpers/dateformat"
import { BankAccountType } from "@/core/models/BankAccountType"
import { CashBookGroup } from "@/core/models/CashBookGroup"
import { useStore } from "vuex"
import { TableColumnCtx } from "element-plus/es/components/table/src/table-column/defaults"
import { currencyForm } from "@/core/helpers/currencyformat"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import { StudentOrder } from "@/core/models/StudentOrder"
import { PaymentScheduleItemSummary } from "@/core/models/PaymentScheduleItemSummary"
import { PaymentItem } from "@/core/models/PaymentItem"
import { useRoute, useRouter } from "vue-router"
import JwtService from "@/core/services/JwtService"
import { ChequePayment } from "@/core/models/ChequePayment"

export default defineComponent({
  components: { ElInputCurrency },
  name: "student-sale-payment",
  inheritAttrs: false,
  props: ["studentId", "branchId", "personId"],
  emits: ["getlist"],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const appUrl = process.env.VUE_APP_URL ?? ""
    const isDrawerOpen = computed(() => route.meta.salePayment)
    const cashbookId = ref()
    const saleList = computed(() => {
      return store.getters.getStudentOrderPaymentScheduledList
    })
    const paymentScheduleList = ref<PaymentScheduleItemSummary[]>([])
    const paymentList = ref<PaymentItem[]>([])
    const payment = ref<Payment>({} as Payment)

    const cashBookList = ref<CashBook[]>([] as CashBook[])
    const cashBookGroupList = ref<CashBookGroup[]>([] as CashBookGroup[])
    const activeBankAccount = ref(BankAccountType.CASH)
    const selectedCheque = ref()
    const newCheque = ref(false)

    const bankList = ref<SelectModel[]>([])

    const order = ref<StudentOrder>({} as StudentOrder)

    const chequeList = ref<Cheque[]>([] as Cheque[])

    const activeCurrencySuffix = "₺"

    type FormInstance = InstanceType<typeof ElForm>

    const paymentRuleFormRef = ref<FormInstance>()

    const isSaleListLoading = ref(false)
    const isPaymentScheduleListLoading = ref(false)
    const isPaymentListLoading = ref(false)
    const isCashBookListLoading = ref(false)
    const isBankSelectLoading = ref(false)
    const isChequeListLoading = ref(false)
    const payLoading = ref(false)
    const switchValue = ref(false)
    const installment = ref(1)

    const dialogImageUrl = ref("")
    const dialogVisible = ref(false)
    const chequeImageDialogVisible = ref(false)

    const isPaid = ref(false)

    const beforeProfilePictureUpload: UploadProps["beforeUpload"] = rawFile => {
      const allowedTypes = ["image/jpeg", "image/png"]
      if (!allowedTypes.includes(rawFile.type)) {
        ElMessage.error("Slip resmi Jpeg veya Png formatında olmalıdır")
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("2MB'dan fazla olamaz")
        return false
      }
      return true
    }

    const handleCreditCardImageSuccess: UploadProps["onSuccess"] = response => {
      payment.value = {
        ...payment.value,
        creditCardPayment: {
          ...payment.value.creditCardPayment,
          receiptImage: response[0],
        },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // profilePictureUrl: URL.createObjectURL(uploadFile.raw!),
        // profilePictureId: response[0],
      }
    }

    const handleChequeFrontImageSuccess: UploadProps["onSuccess"] = response => {
      payment.value = {
        ...payment.value,
        chequePayment: {
          ...payment.value.chequePayment,
          frontImageId: response[0],
        },
      }
    }

    const handleChequeBackImageSuccess: UploadProps["onSuccess"] = response => {
      payment.value = {
        ...payment.value,
        chequePayment: {
          ...payment.value.chequePayment,
          backImageId: response[0],
        },
      }
    }

    const beforeCrediCartRemove: UploadProps["beforeRemove"] = () => {
      payment.value.creditCardPayment.receiptImage = ""
      return true
    }

    const beforeChequeFrontRemove: UploadProps["beforeRemove"] = () => {
      payment.value.chequePayment.frontImageId = ""
      return true
    }

    const beforeChequeBackRemove: UploadProps["beforeRemove"] = () => {
      payment.value.chequePayment.backImageId = ""
      return true
    }

    watch(
      () => route.meta.salePayment,
      async newValue => {
        if (newValue) {
          openDrawer()
          handleChange(activeBankAccount.value)
        }
      }
    )

    watch(
      () => newCheque.value,
      async newValue => {
        if (newValue) {
          await getBankList()
          await getAccountCard()
          selectedCheque.value = ""
          payment.value = {
            ...payment.value,
            chequePayment: {
              ...payment.value.chequePayment,
              chequeId: "",
              branchName: "",
              issuePlace: "",
              chequeNumber: "",
              backImageId: "",
              frontImageId: "",
              amount: 0,
              dueDate: today(),
            } as ChequePayment,
          } as Payment
        }
      }
    )

    interface SummaryMethodProps<T> {
      columns: TableColumnCtx<T>[]
      data: T[]
    }

    function getSummaries<T>(param: SummaryMethodProps<T>, isPaymentSummary = false) {
      const { columns, data } = param
      const sums: string[] = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = isPaymentSummary ? "" : ``
          return
        }

        if (index === 1) {
          sums[index] = isPaymentSummary
            ? ""
            : `Kalan: ${currencyForm(totalPrice.value - order.value.paidAmount)}`
          return
        }

        if (column.property == "amount" && !isPaymentSummary) {
          sums[index] = `Ödenen: ${currencyForm(order.value.paidAmount)}`
          return
        }

        const values = data.map(item => Number(item[column.property]))
        if (values.every(value => Number.isNaN(value))) {
          sums[index] = ""
          return
        }

        const itemValue = values.reduce((prev, curr) => {
          const value = Number(curr)
          if (!Number.isNaN(value)) {
            return prev + curr
          } else {
            return prev
          }
        }, 0)

        if (itemValue === 0) {
          sums[index] = ""
        } else {
          if (isPaymentSummary) {
            sums[index] = `Toplam: ${currencyForm(parseFloat(itemValue.toFixed(2)))}`
          } else {
            sums[index] = `${currencyForm(
              totalPrice.value - parseFloat(itemValue.toFixed(2))
            )} / ${currencyForm(parseFloat(itemValue.toFixed(2)))}`
          }
        }
      })

      return sums
    }

    function getPaymentSummaries<T>(param: SummaryMethodProps<T>) {
      return getSummaries(param, true)
    }

    const getCashBook = async branchId => {
      cashBookGroupList.value = []
      isCashBookListLoading.value = true
      const { data } = await ApiService.get("cash-book/branch/" + branchId)
      filterCashBook(data)
    }

    const filterCashBook = async data => {
      cashBookList.value = data.map(x => ({ ...x, bankAccount: { ...x.bankAccount } }))
      const cashBookCashList = data.filter(x => x.bankAccountType == null)
      const cashBookPosList = data.filter(x => x.bankAccountType == BankAccountType.POS)
      const cashBookChequeList = data.filter(x => x.bankAccountType == BankAccountType.CHEQUE)
      const cashBookEftList = data.filter(x => x.bankAccountType == BankAccountType.EFT)

      const accountTypeMappings = [
        {
          type: "1",
          list: cashBookCashList,
          targetType: BankAccountType.CASH,
        },
        {
          type: "2",
          list: cashBookPosList,
          targetType: BankAccountType.POS,
        },
        {
          type: "3",
          list: cashBookChequeList,
          targetType: BankAccountType.CHEQUE,
        },
        {
          type: "4",
          list: cashBookEftList,
          targetType: BankAccountType.EFT,
        },
      ]

      for (const mapping of accountTypeMappings) {
        if (activeBankAccount.value === mapping.targetType && mapping.list.length > 0) {
          cashBookGroupList.value.push({
            bankAccountType: mapping.targetType,
            bankAccountTypeName: "",
            cashBookList: mapping.list,
          })
        }
      }
      isCashBookListLoading.value = false
    }

    const getPaymentScheduleList = async invoiceId => {
      isPaymentScheduleListLoading.value = true
      const { data } = await ApiService.get("invoice/" + invoiceId + "/payment-schedule")
      isPaymentScheduleListLoading.value = false

      const temp = [...data] as PaymentScheduleItemSummary[]

      let sum = 0
      for (const element of temp) {
        sum = sum + element.amount
        element.isPaid = order.value.paidAmount >= sum
        element.isOverdue =
          !element.isPaid && element.date < (new Date().toISOString() as unknown as Date)
      }
      paymentScheduleList.value = data
      isPaid.value = order.value.paidAmount >= order.value.price
    }

    const getPaymentList = async invoiceId => {
      isPaymentListLoading.value = true
      const { data } = await ApiService.get("invoice/" + invoiceId + "/payments")
      paymentList.value = data
      isPaymentListLoading.value = false
    }

    const getAccountCard = async () => {
      const { data } = await ApiService.get("account-card/" + order.value.accountCardId)
      payment.value.chequePayment.indorserUser = data.shortName
    }

    const getChequeList = async () => {
      isChequeListLoading.value = true
      const { data } = await ApiService.get("cheque/account-card/" + order.value.accountCardId)
      chequeList.value = data
      isChequeListLoading.value = false
    }

    const handleChequeChange = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cheque = chequeList.value.find((item: any) => item.id === selectedCheque.value)
      if (cheque) {
        payment.value.chequePayment = {
          ...payment.value.chequePayment,
          chequeId: cheque.id,
          branchName: cheque.branchName,
          issuePlace: cheque.issuePlace,
          chequeNumber: cheque.chequeNumber,
          indorserUser: cheque.indorserUser,
          amount: cheque.amount,
          dueDate: cheque.dueDate,
        } as ChequePayment
      }
    }
    const validatePrice = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (order.value && value > order.value.price)
        callback(new Error("Ücret satış tutarından büyük olamaz"))
      else callback()
    }

    const formData = computed(() => ({
      selectedChequeList: selectedCheque.value,
      ...payment.value,
    }))

    const paymentRules = ref({
      cashbookId: getRule(RuleTypes.SELECT, "Hesap"),
      paymentAmount: getRule(RuleTypes.NUMBER, "Tutar", [
        {
          validator: validatePrice,
          trigger: "blur",
          required: true,
        } as FormItemRule,
      ]),
      "creditCardPayment.receiptCode": getRule(RuleTypes.TEXT50, "Slip Onay Kodu"),
      "creditCardPayment.installmentCount": getRule(RuleTypes.NUMBER, "Taksit Sayısı"),
      "chequePayment.amount": getRule(RuleTypes.NUMBER, "Çek Tutarı"),
      "chequePayment.dueDate": getRule(RuleTypes.DATE, "Vade Tarihi"),
      selectedChequeList: getRule(RuleTypes.SELECT, "Çek"),
    })

    const totalPrice = ref(0)

    const getSale = async id => {
      const response = await ApiService.get("student-order/" + id)
      order.value = response.data
      totalPrice.value = order.value.price
      paymentRuleFormRef.value?.resetFields()

      await getPaymentScheduleList(order.value.invoiceId)
      await getPaymentList(order.value.invoiceId)
    }

    const getBankList = async () => {
      isBankSelectLoading.value = true
      const { data } = await ApiService.get("bank")
      bankList.value = data
      isBankSelectLoading.value = false
    }

    const clearPayment = () => {
      paymentRuleFormRef.value?.resetFields()
    }

    const closeDrawer = async () => {
      router.push({ name: "student-sale", params: { student_id: route.params.student_id } })
      clearPayment()
      activeBankAccount.value = BankAccountType.CASH
    }

    const openDrawer = async () => {
      await getSale(route.params.student_order_id)
    }

    const onPaymentDrawerClose = () => {
      order.value = {} as StudentOrder
      closeDrawer()
      emit("getlist")
    }

    const handlePayment = async formEl => {
      if (!formEl) return

      const isValid = await formEl.validate()

      if (newCheque.value) {
        if (!payment.value.chequePayment.frontImageId || !payment.value.chequePayment.backImageId) {
          ElMessage.error("Çek Ön ve Arka Yüzü Yüklenmelidir")
          return
        }
      }

      if (isValid) {
        payLoading.value = true

        try {
          await addPayment()
          formEl.resetFields()
          handleChange(activeBankAccount.value)
        } finally {
          payLoading.value = false
        }
      }
    }

    const addPayment = async () => {
      const addData = {
        ...payment.value,
        invoiceId: order.value.invoiceId,
      }
      await ApiService.post("payment/add", addData)
      await getSale(route.params.student_order_id)
      console.log(activeBankAccount.value)
    }

    const handleReceipt = async id => {
      await ApiService.report({
        report: ReportType.Receipt,
        payload: JSON.stringify({
          transactionId: id,
        }),
      })
    }

    const rowClass = ({ row }) => {
      if (row.isPaid) return "success-row"
      else if (row.isOverdue) return "danger-row"
    }

    const handleChange = (name: TabPanelName) => {
      clearPayment()
      getCashBook(props.branchId)
      switch (name) {
        case BankAccountType.CASH:
          activeBankAccount.value = BankAccountType.CASH
          payment.value = {
            paymentMethod: 1,
            paymentAmount: 0,
          } as Payment
          break
        case BankAccountType.POS:
          activeBankAccount.value = BankAccountType.POS
          payment.value = {
            paymentMethod: 3,
            paymentAmount: 0,
            creditCardPayment: {
              installmentCount: 1,
            },
          } as Payment
          break
        case BankAccountType.CHEQUE:
          newCheque.value = false
          getChequeList()
          activeBankAccount.value = BankAccountType.CHEQUE
          payment.value = {
            paymentMethod: 4,
            paymentAmount: 0,
            chequePayment: {
              chequeId: "",
              dueDate: today(),
              amount: 0,
            },
          } as Payment
          break
        case BankAccountType.EFT:
          activeBankAccount.value = BankAccountType.EFT
          payment.value = {
            paymentMethod: 2,
            paymentAmount: 0,
          } as Payment
          break
      }
    }

    const authHeader = {
      Authorization: `Bearer ${JwtService.getToken()}`,
    }

    return {
      paymentRules,
      paymentRuleFormRef,
      cashbookId,
      payment,
      saleList,
      cashBookList,
      paymentList,
      bankList,
      chequeList,
      paymentScheduleList,
      cashBookGroupList,
      isSaleListLoading,
      isPaymentScheduleListLoading,
      isPaymentListLoading,
      isChequeListLoading,
      payLoading,
      isDrawerOpen,
      order,
      Money,
      Close,
      Document,
      DateFormatTypes,
      dateForm,
      openDrawer,
      closeDrawer,
      onPaymentDrawerClose,
      handlePayment,
      handleReceipt,
      rowClass,
      handleChange,
      SaleStepEnum,
      StudentOrderStateEnum,
      activeCurrencySuffix,
      activeBankAccount,
      getSummaries,
      getPaymentSummaries,
      currencyForm,
      switchValue,
      newCheque,
      installment,
      dialogImageUrl,
      dialogVisible,
      chequeImageDialogVisible,
      BankAccountType,
      beforeProfilePictureUpload,
      handleCreditCardImageSuccess,
      handleChequeFrontImageSuccess,
      handleChequeBackImageSuccess,
      beforeCrediCartRemove,
      beforeChequeFrontRemove,
      beforeChequeBackRemove,
      authHeader,
      handleChequeChange,
      selectedCheque,
      formData,
      isPaid,
      appUrl,
    }
  },
})
